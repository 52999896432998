<template>
    <div
        id="demo"
        class="certificatebyqms"
        data-apiKey="srmBRu0qTITgR8Aagsa2RHJd0pSNQNiB2QfGr/6RFCOd8qkoyfjTvioBO178d7qcmtO/71BNL8ioMr6s/tdMt3tCQzk2MEZDNy1DRDI3LTRFMDAtOEIzRC0xRkEwOEZFNzVCNTB9"
        data-isGreyBadge="false"
    >
    </div>
</template>

<script>
export default {
    name: 'QmsCertificate',
    data() {
        return {
            isMounted: false,
            isoCertificateScript: null,
        };
    },
    methods: {
        getISOCertificate() {
            try {
                this.isoCertificateScript = document.createElement('script');
                this.isoCertificateScript.setAttribute('data-apiKey', 'srmBRu0qTITgR8Aagsa2RHJd0pSNQNiB2QfGr/6RFCOd8qkoyfjTvioBO178d7qcmtO/71BNL8ioMr6s/tdMt3tCQzk2MEZDNy1DRDI3LTRFMDAtOEIzRC0xRkEwOEZFNzVCNTB9');
                this.isoCertificateScript.setAttribute('src', 'https://qmsprodstorage.blob.core.windows.net/images/certificate-renderer.js');
                this.isoCertificateScript.onerror = function () {
                    noop();
                };
                if (this.isMounted) {
                    document.head.appendChild(this.isoCertificateScript);
                }
            } catch (error) {
                noop();
            }
        },
    },
    mounted() {
        this.isMounted = true;
        this.getISOCertificate();
    },
    beforeUnmount() {
        this.isMounted = false;
        if (this.isoCertificateScript) {
            document.head.removeChild(this.isoCertificateScript);
        }
    },
};
</script>

<style lang="scss">

.certificatebyqms {
    height: 112px;
    width: 195px;
}

.qmsuk-badge {
    img {
        height: 66px;
        width: 175px;
    }

    span {
        font-size: 11px !important;
    }
}

</style>