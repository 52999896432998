export default {
    methods: {
        checkImage(imageSrc, success, error) {
            let img = new Image();
            img.onload = success;
            img.onerror = error;
            img.src = imageSrc;
        },
    },
};
// used not only in cp