<template>
    <div
        class="b-cp-layout"
        :class="layoutClass"
    >
        <notifications ref="notifications"></notifications>

        <header class="b-cp-layout__header">
            <div class="b-cp-layout__header-styling u-primary-color__bg"></div>
            <cp-header
                v-if="showHeader"
                :isSaving="isSaving"
            ></cp-header>
        </header>

        <div
            class="b-cp-layout__content"
            :class="contentClass"
        >
            <leave-impersonate
                v-if="isImpersonating || isNovaImpersonating"
            ></leave-impersonate>
            <!-- *** -->
            <router-view @update:isSaving="isSaving = $event"></router-view>
            <!-- *** -->
        </div>

        <div class="b-nal-layout__footer b-cp-layout__footer">
            <nal-footer
                :on-client-portal="true"
            ></nal-footer>
        </div>

        <cookie-banner></cookie-banner>

        <transition name="t-modal">
            <modal-component
                v-if="showModal"
                :modal-style="nal.modal.style"
                :has-no-padding="nal.modal.hasNoPadding"
                :is-loading="nal.modal.isLoading"
                :document="nal.modal.document"
                @back="closeCurrentModal"
                @close="closeAllModals"
                v-on="nal.modal.on"
            >
                <keep-alive>
                    <component
                        :is="nal.modal.component"
                        :key="nal.modal.key"
                        :data="nal.modal.data"
                        v-bind="nal.modal.data"
                        @back="closeCurrentModal"
                        @close="closeAllModals"
                        v-on="nal.modal.on"
                    ></component>
                </keep-alive>
            </modal-component>
        </transition>
    </div>
</template>

<script>
import CpHeader from '@js/client-portal/vue-components/layout/cp-header.vue';
import NalFooter from '@js/vue-components/layout/nal-footer.vue';
import LeaveImpersonate from '@js/nal/vue-components/layout/leave-impersonate.vue';

import { DEFAULT_CURRENCY } from '@js/core/currencies.js';
import interactsWithModalComponent from '@js/vue-mixins/interactsWithModalComponent.js';
import { defineAsyncComponent } from 'vue';
import providesImpersonate from '@js/vue-mixins/providesImpersonate.js';

export default {
    name: 'CpApp',
    components: {
        CpHeader,
        NalFooter,
        LeaveImpersonate,
    },
    provide() {
        return {
            userCurrencyState: this.userCurrencyState,
        };
    },
    mixins: [
        interactsWithModalComponent,
        providesImpersonate,
    ],
    data() {
        return {
            isSaving: false,
            userCurrencyState: {
                currency: null,
            },
        };
    },
    computed: {
        showHeader() {
            const noNavPages = ['register', 'login', 'recover', 'reset', 'invalid', 'verify'];
            const currentPage = this.$route.name;
            return !noNavPages.some(page => page === currentPage);
        },
        layoutClass() {
            return this.showHeader ? 'b-cp-layout--common' : 'b-cp-layout--single-section u-full-page-center__body';
        },
        contentClass() {
            return this.showHeader ? '' : 'u-full-page-center__outer';
        },
        userCurrency() {
            return _(this.nal).get('user.settings.currency', DEFAULT_CURRENCY);
        },
    },
    methods: {
        showCPHelpModal() {
            this.createModal({
                data: {
                    component: defineAsyncComponent(() =>
                        import('@js/vue-components/general/content-display/video-modal.vue')),
                    hasNoPadding: true,
                    style: { width: '700px', height: '440px' },
                    data: {
                        videoLink: 'https://player.vimeo.com/video/263875174?title=0&byline=0&portrait=0',
                    },
                },
            });
        },
    },
    watch: {
        userCurrency: {
            immediate: true,
            handler(newCurrency) {
                this.userCurrencyState.currency = newCurrency;
            },
        },
    },
    mounted() {
        if (this.nal.modal.component) {
            this.createModal(this.nal.modal);
        }
    },
};
</script>